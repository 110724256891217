import { ref, nextTick, onMounted, onBeforeUnmount, onActivated, onDeactivated, onUpdated } from 'vue'
import debounce from 'lodash/debounce'

export const usePagePosition = (el: Ref) => {
  const pagePosition: Ref<number> = ref(0)
  const route = useRoute()

  const handleScroll = () => {
    const y = el.value?.scrollTop ?? 0
    _setItem(route.name, y)
    pagePosition.value = y
    // console.log(`页面滚动🏳️‍🌈:`, y)
  }

  // 節流處理
  const debounceScroll = debounce(handleScroll, 50)

  // 页面激活时，读取 sessionStorage 的位置数据
  onActivated(async () => {
    // console.log('onActivated')
    el.value?.removeEventListener('scroll', debounceScroll)
    const scrollTop = _getItem(route.name)
    // console.log('clientHeight:', el.value?.clientHeight)
    await nextTick()
    if (el.value) {
      el.value.scrollTop = scrollTop
      // console.log(`${route.name}_页面激活🟢:`, scrollTop)
    }
    el.value?.addEventListener('scroll', debounceScroll)
  })

  // 页面失活时，保存位置到 sessionStorage
  onDeactivated(() => {
    _setItem(route.name, pagePosition.value)
    // console.log(`${route.name}_页面失活🟠:`, pagePosition.value)
  })

  onMounted(() => {
    el.value?.addEventListener('scroll', debounceScroll)
    // console.log('onMounted')
  })

  onBeforeUnmount(() => {
    el.value?.removeEventListener('scroll', debounceScroll)
  })

  return { pagePosition }
}

function _setItem(key: string, val: any) {
  let data: any = sessionStorage.getItem('pagePosition')

  if (!data) {
    sessionStorage.setItem('pagePosition', JSON.stringify({ [key]: val }))
  }

  if (data) {
    try {
      data = JSON.parse(data)
      data[key] = val
      sessionStorage.setItem('pagePosition', JSON.stringify(data))
    } catch (err) {
      data = 0
    }
  }
}

function _getItem(key: string) {
  let data: any = sessionStorage.getItem('pagePosition')

  if (!data) {
    return 0
  }

  if (data) {
    try {
      data = JSON.parse(data)
    } catch (err) {
      data = 0
    }
    return data[key]
  }
}
